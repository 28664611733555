/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
  height: 3px; /* Height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

.chatcontainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  width: 100%;
  padding-top: 0px;
}

.chatlistcontainer {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
}
.notescontainer {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
}
.chatsendingbarcontainer {
  height: 80px;
}
.chatinput {
  border: none;
  flex: 1 1;
  font-size: 12px;
  max-height: 120px;
  resize: none;
  width: 100%;
  /* background: rgb(237, 238, 241) none repeat scroll 0 0; */
}

.chatinput:focus {
  border: none;
  outline: none;
}

.chatinputbox {
  background: rgb(237, 238, 241) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 38px;
  width: 100%;
  border: 1px solid #e3e6f0;
  border-radius: 5px;
  padding-left: 13px;
  padding-right: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chatinputcontainer {
  width: 100%;
  min-height: 50px;
  border-radius: 1px;
  /* background-color: grey; */
  border: 1px solid rgb(216, 216, 216);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  gap: 5px;
}

.sendbtn {
  width: 45px;
  background: #297ec6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}

.previewcontainer {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewimg {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.closebtn {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: #cecece52;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pdfbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sendermessagebox {
  background: #c2d9ff none repeat scroll 0;
  box-shadow: 2px 2px 2px #ccc;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #000;
  padding: 18px 24px 15px 12px;
  width: 70%;
  align-self: flex-end;
  border-bottom-right-radius: 0px;
  margin-right: 3px;
}

.receivermessagebox {
  background: #e0e0e0b5 none repeat scroll 0;
  box-shadow: 2px 2px 2px #ccc;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  padding: 18px 24px 15px 12px;
  width: 70%;
  color: #000;
  border-bottom-left-radius: 0px;
}

.noteitem {
  font-size: 14px;
  margin: 0;
  /* padding: 18px 24px 15px 12px; */
  width: 85%;
  color: #393939;
  border-bottom-left-radius: 0px;
}

.messageitem {
  display: flex;
  flex-direction: column;
}

.datelabelright {
  position: absolute;
  bottom: -8px;
  /* left: 0; */
  color: #393939;
  display: block;
  font-size: 9px;
  margin-bottom: 8px;
  right: 2px;
}
.datelabelleft {
  position: absolute;
  bottom: -8px;
  color: #383838;
  display: block;
  font-size: 9px;
  margin-bottom: 8px;
  right: 3px;
  font-weight: 500;
}

.chatimage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 250px;
}

/* src/Chat/ChatMessage.css */

.shared-file {
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: #000;
}

.file-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  margin-right: 8px; /* Space between icon and file name */
}

.file-name {
  font-size: 14px; /* Adjust font size as needed */
  color: #333; /* Dark text color */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for long file names */
  white-space: nowrap; /* Prevent text from wrapping */
}

.nochats {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #a2a2a2;
}

.linkmsg {
  cursor: pointer;
  color: rgba(31, 31, 255, 0.703);
  text-decoration: underline;
  word-wrap: break-word;
}

.chattab {
  border-radius: 0px;
  padding: 6px 15px;
  background: #fff;
  border: 1px solid #ffffff;
  min-width: 120px;
  text-align: center;
  color: #858796;
  font-size: 13px;
  font-weight: 500;
  align-self: flex-end;
  cursor: pointer;
}

.chattabcontainer {
  border-bottom: 0.1px solid #77777761;
}

.selectedchattab {
  border-bottom: 3px solid grey;
}

.notetimeago {
  font-size: 12px;
  color: #a2a2a2;
  font-weight: 400;
}

.notefileitem {
  background-color: #d8d8d8;
  width: 70%;
  padding: 10px;
}

.userimg {
  width: 41px;
  height: 41px;
  object-fit: contain;
  border-radius: 50px;
  background-color: #8585853c;
}
