.changepassword {
  text-decoration: underline;
  font-size: 12px;
  color: #0040ff;
  font-weight: 600;
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}

.profileitem {
  border-bottom: 0.05rem solid #e3e6f0;
  padding: 6px 0px;
}

.itemlabel {
  font-size: 14px;
  font-weight: 500;
}

.itemvalue {
  font-size: 13px;
  font-weight: 400;
}

.changeprofile {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.profileupdate {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
