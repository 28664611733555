.candidate-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
}
.chatpanel {
  min-width: 350px;
  width: 30%;
  display: flex;
  flex: 1;

  background-color: #fff;
  max-height: 83vh;
  position: sticky;
  top: 30px;
  /* height: 100%; */
}

.candidatemainpanel {
  width: 68%;
  background-color: #fff;
}

.profilecard {
  display: flex;
  gap: 5px;
}

.otherdetailscard {
  font-size: 13px;
  color: #858796;
}

.candidate-container .table td:nth-child(odd) {
  background-color: #fff !important; /* Ensure this is applied */
}

/* Increased specificity */
.candidate-container .table tr td:nth-child(odd) {
  background-color: #fff !important; /* Added rule */
}

.orderdetailscard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.orderdataitem {
  text-align: center;
  color: #858796;
  min-width: 150px;
}

.selectedStatus {
  max-width: 200px;
  min-height: 30px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-family: Arial;
  padding: 3px 10px;
  font-size: 10px;
  color: #ffffff !important;
  background: #297ec6 !important;
  cursor: pointer;
  gap: 10px;
}

.optionscontainer {
  width: 200px;
  position: absolute;
  box-shadow: 1px 1px 3px 1px black;
  top: 45px;
  background-color: #fff;
}

.chevron-icon {
  stroke: #ffffff; /* Change to your desired color */
  stroke-width: 3; /* Adjust the stroke width as needed */
}

.optionitem {
  font-size: 13px;
}

.candidatename {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #858796;
  text-align: left;
}

.candidatedesc {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
}

.statusselect {
  margin: 10px 0px;
}

.candidatesinglecard {
  background: #f8f9fc;
  padding: 1rem 1rem;
  flex-wrap: wrap;
}

.cvhead {
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  align-items: center;
  padding: 10px 10px;
  gap: 20px;
}

.copybtn {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  /* background-color: rgb(195, 195, 195); */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
}

.openpdfbtn {
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 21px;
  box-shadow: 1px 2px 4px rgb(0 0 0 / 6%);
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}

.min-100 {
  min-width: 100px;
}

.maxwidth-250 {
  max-width: 250px;
}

.statuscontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.statuslbl {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: grey;
  white-space: nowrap;
}
.height-30 {
  height: 31px !important;
  padding: 0px 10px;
}

.exportbtn {
  padding: 5px 9px;
  background-color: #0d6dfdee;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
}

.exportbtn:hover {
  background-color: #0066ff; /* Darker green on hover */
}
.downloadbtn {
  padding: 5px 9px;
  background-color: #00c91bee;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
}

.downloadbtn:hover {
  background-color: #01a617ee; /* Darker green on hover */
}

.maxwidth-350 {
  max-width: 350px;
}

.searchbar {
  max-width: 90% !important;
}

.floating-status {
  position: absolute;
  top: -7px;
  left: 7px;
  font-size: 10px;
  background-color: #fff;
  color: grey;
}

.downloadzipbtn {
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 10px;
  background-color: grey;
}
@media (max-width: 1120px) {
  .chatpanel {
    width: 100%;
    height: 70vh;
    min-width: 200px;
  }
  .candidatemainpanel {
    width: 100%;
  }
}
