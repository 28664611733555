.notificationcontent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  /* align-items: center; */
  /* width: 90%; */
  background-color: #ffffff;
  margin: 10px;
}

.notificationitem {
  display: flex;
  gap: 10px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #d0d0d0;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
  align-items: center;
}

.bellicon {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #297ec6;
  color: #fff;
  min-width: 40px;
}

.datetext {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  font-size: 9px;
  color: rgb(135, 144, 158);
  font-weight: 500;
  white-space: nowrap;
}

.notificationtext {
  font-size: 12px;
  font-weight: 500;
  color: grey;
}

.nt-title {
  font-size: 14px;
  font-weight: 600;
}

.notificationfooter {
  height: 40px;
  background-color: red;
}

/* .unreadcount {
  position: absolute;
  font-size: 10px;
  top: 0px;
  color: #ffffff;
  background-color: red;
  border-radius: 24px;
  font-weight: 700;
  min-width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.unreadcount {
  position: absolute;
  top: -5px;
  right: -4px;
  align-items: center;
  background-color: #ea4d4d;
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  height: 14px;
  justify-content: center;
  padding: 0 4px;
  z-index: 10;
}
