.active_tab{
    background-color: #1b0101;
    text-decoration-line: none;
    text-align: center;
    padding: 4px 0px;
    margin-bottom: 3px;
    border-radius: 5px;
    color: white;
}
.active_tab:hover{
    color: white;
}
.non-active:hover{
    border: 1px solid #d2d2d2;

}
.non-active{
    text-decoration-line: none;
    text-align: center;
    padding: 4px 0px;
    margin-bottom: 3px;
    border-radius: 5px;
    color: black;
    border: 1px solid #d2d2d257;

}
.logout_contain{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

}
.logout_btn{
    border: none;
    padding: 5px 2px;
    border-radius: 5px;
    margin-top: 5px;
    width: 89%;
    background-color: #110000;
    cursor: pointer;
    color: white;
  
}

.global_container{
    height: 100svh;
    display: flex;
    flex-direction: column; 
}
.side_bar_outlet_contain{
    flex: 1;
    display: flex;
}
.outlet_contain{
    flex-grow: 1;
    padding: 10px;
}