.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top of everything */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll   
     if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
}

.modal-content {
  background-color: #fefefe;

  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* 80% width  */
  position: relative;
  min-width: 300px;
  max-width: 500px;
  border-radius: 10px;
  padding: 20px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 33px;
  font-weight: bold;
  position: absolute;
  top: 0px;
  right: 10px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.show {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headercontainer {
  border-bottom: 1px solid #d0d0d0;
  display: grid;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.modaltitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

.modalsubtitle {
  color: grey;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.warning-buttons {
  width: 100px;
  height: 30px;
  gap: 0px;
  border-radius: 50px;
  font-family: Avenir Next;
  font-size: 18px;
  line-height: 21.09px;
  text-align: center;
  /* background-color: #02748e; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5b4444;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #02748e;
}
.changepasswordbutton {
  background-color: #293e5c;
  color: #fff;
  width: 130px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 8px;
}
