.userlistcard {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border: 1px solid #eee;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}

.userheadicon {
  height: 30px;
  object-fit: contain;
}

.userlisthead {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #737686;
  font-size: 1.25rem;
}

.searchbar {
  padding: 4px 10px;
  max-width: 70%;
  width: 500px;
  display: block;
  width: 100%;
  height: 31px;
  padding: 11px;
  font-size: 12px;
  font-weight: 400;
  line-height: 0.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #dde2f1;
}

.adduserbtn {
  /* width: 75px; */
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dde2f1;
  border-color: #d4daed;
  padding: 2px 10px;
  border-radius: 4px;
  gap: 3px;
  font-size: 12px;
  cursor: pointer;
}

.searchbarcontainer {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photouploadicon {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 3px solid #6b6bff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.camera-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.useradd .form-control {
  align-items: center;
  /* background-color: rgb(250, 251, 252); */
  box-sizing: border-box;
  color: rgb(9, 30, 66);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 1.42857;
  max-width: 100%;
  overflow-wrap: break-word;
  border-color: rgb(223, 225, 230);
  border-radius: 8px;
  border-style: solid;
  flex: 1 0 auto;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  border-width: 1px;
  padding: 6px;
}

.form-group {
  position: relative;
}

/* Material like input */
input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label,
textarea:focus ~ .floating-label,
textarea:not(:focus):valid ~ .floating-label {
  top: -8px;
  bottom: 10px;
  left: 11px;
  background-color: white;
  height: fit-content;
  padding: 0px 5px;
  font-size: 11px;
  opacity: 1;
}

/* input:not(:focus):invalid {
  border: 1.8px solid red
} */

/* .inputText {
  font-size: 14px;
  width: 200px;
  height: 35px;
} */

.floating-label,
input[value='']:not(:focus) ~ .floating-label,
textarea[value='']:not(:focus) ~ .floating-label {
  position: absolute;
  pointer-events: none;
  color: #b2b2b2;
  left: 16px;
  top: 10px;
  transition: 0.2s ease all;
}

.form-control:focus {
  border: 1px solid rgb(103, 103, 255) !important;
  box-shadow: none;
}

.has-error .form-control {
  color: #ff4a55 !important;
  /* border-color: #ff4a55 !important; */
  border: 1px solid #ff4a55 !important;
}
.error {
  color: #ff4a55;
}
.has-error .error {
  color: #ff4a55;
}

.statusidicator {
  background-color: #293e5c;
  border-color: #202a3a;
  padding: 1px 9px;
  color: black;
  font-size: 13px;
  font-weight: 500;
  /* width: 66px; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px 9px;
  margin: 0px 25px;
}

.statusidicator:hover {
  background-color: #2e59d9;
  border-color: #2653d4;
  color: #fff;
}
.centertd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: auto;
  margin-bottom: 0px;
}

.resettxt {
  text-decoration: underline;
  font-weight: 600;
  font-size: 12px;
  color: #2653d4;
}

.userimgicon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 30px;
}

.cursor-pointer {
  cursor: pointer;
}
