.addrowbtn {
  /* padding: 10px 10px; */
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}
.uploadjd {
  padding: 2px 10px;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
  background-color: #293e5c;
  border-color: #202a3a;
  cursor: pointer;
}
.extrafieldscontainer {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.optiontitle {
  font-size: 14px;
  font-weight: 600;
  color: #252525;
  padding: 3px 0px;
}
.optionsubtitle {
  font-size: 12px;
  font-weight: 500;
  color: #252525;
}
.width-200 {
  width: 200px;
}

.savebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #293e5c;
  border-radius: 4px;
  width: 100px;
  padding: 8px 0px;
  cursor: pointer;
}

.uploadedjd {
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  color: green;
  transition: font-size 0.3s ease; /* Added transition effect */
  align-items: center;
  justify-content: center;
  position: relative;
}

.uploadedjd:hover {
  font-size: 16px; /* Added 'px' unit to the font-size */
}

.removejd {
  position: absolute;
  bottom: 0;
  color: grey;
  font-size: 10px;
}

.closeextrafield {
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.showerror {
  border: 1px solid red;
  background-color: red;
}

.ordersingleviewContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sidepanel {
  padding-left: 8px;
  border-right: 4px solid #e4e8f1;
  padding-right: 8px;
  min-height: calc(100vh - 130px);
  width: 200px;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.mainpanel {
  background-color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  width: calc(100% - 200px); /* Updated width */
}

.descitem {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 180px;
}

.desclabel {
  color: #858796;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
}

.descvalue {
  color: #858796;
  font-size: 13px;
  align-items: center;
  word-wrap: break-word;
  width: 111px;
  text-align: right;

  /* font-weight: 500; */
}

.orderlistcontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.orderitem {
  margin-bottom: 5px;
  padding: 4px;
  font-weight: 600;
  border-radius: 5px;
  color: #5d5d5d;
  border: 1px solid #9ed4f5;
  position: relative;
  width: 180px;
}
.ordertitle {
  font-size: 14px;
  font-weight: 600;
}

.ordercontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.counttxt {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  width: 73px;
}

.countlbl {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}
.selectedOrder {
  background-color: #ddf2ff;
}

.tabscontainer {
  border-bottom: 3px solid #dddfeb;
  display: flex;
  /* justify-content: s pace-between; */
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.tabitem {
  border-radius: 0px;
  padding: 15px 15px;
  background: #fff;
  border: 1px solid #ffffff;
  min-width: 120px;
  text-align: center;
  color: #858796;
  font-size: 13px;
  font-weight: 500;
  align-self: flex-end;
}

.selectedtab {
  color: #449de4;
  border-bottom: 3px solid #449de4;
  margin-bottom: -2px;
}

.orderdetails {
  display: flex;
  flex-wrap: wrap;
}

.mailbtn {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.mailbtn:active {
  transform: scale(1.1); /* Scale up the button when pressed */
}

.async-label {
  font-size: 13px;
  color: grey;
}
.addcandidate {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 29px;
  right: 39px;
  background-color: #3f5a84;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.detailitem {
  width: 200px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #858796;
  font-weight: 500;
  flex-direction: column;
}

.nameabb {
  width: 36px;
  height: 36px;
  background: #297ec6;
  color: #fff;
  border-radius: 50%;
  display: block;
  line-height: 36px;
  text-align: center;
}

@media (max-width: 768px) {
  .sidepanel {
    width: 100%;
    min-height: 100px;
    margin-bottom: 10px;
  }

  .mainpanel {
    width: 100%;
  }
}
