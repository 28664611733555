.login_container {
  background-color: #40627c;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;
  box-sizing: border-box;
  color: rgb(23, 43, 77);
  background: rgb(255, 255, 255);
  border-radius: 3px;
  padding: 48px;
  color: rgb(122, 133, 150);
}
.login-box h2 {
  margin-top: 0px;
  margin-bottom: 20px;
}
.has-error .form-control {
  color: #ff4a55 !important;
  /* border-color: #ff4a55 !important; */
  border: 1px solid #ff4a55 !important;
}
.error {
  color: #ff4a55;
}
.has-error .error {
  color: #ff4a55;
}

.login-box .form-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(250, 251, 252);
  box-sizing: border-box;
  color: rgb(9, 30, 66);
  display: flex;
  font-size: 14px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  line-height: 1.42857;
  max-width: 100%;
  overflow-wrap: break-word;
  border-color: rgb(223, 225, 230);
  border-radius: 3px;
  border-style: solid;
  flex: 1 0 auto;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  border-width: 2px;
  padding: 6px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #858796;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-width: 100px;
}

.btn-primary {
  color: #fff;
  background-color: #183247;
  border-color: #183247;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2e59d9;
  border-color: #2653d4;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}

.loginlogo {
  width: 150px;
  object-fit: contain;
}

/* Material like input */
input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label,
textarea:focus ~ .floating-label,
textarea:not(:focus):valid ~ .floating-label {
  top: -8px;
  bottom: 10px;
  left: 11px;
  background-color: white;
  height: fit-content;
  padding: 0px 5px;
  font-size: 11px;
  opacity: 1;
}

/* input:not(:focus):invalid {
  border: 1.8px solid red
} */

/* .inputText {
  font-size: 14px;
  width: 200px;
  height: 35px;
} */

.floating-label,
input[value='']:not(:focus) ~ .floating-label,
textarea[value='']:not(:focus) ~ .floating-label {
  position: absolute;
  pointer-events: none;
  color: #b2b2b2;
  left: 16px;
  top: 10px;
  transition: 0.2s ease all;
}

.form-control:focus {
  border: 1px solid rgb(103, 103, 255) !important;
  box-shadow: none;
}

.eye {
  position: absolute;
  top: 6px;
  right: 5px;
  font-size: 10px;
  cursor: pointer;
}
