.dashboard_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.subcontainer {
  width: 30%;
  min-width: 320px;
}

.maincontainer {
  width: 65%;
}

.countbox {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: 10px;
  min-height: 150px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(187, 198, 220, 0.5);
}

.dashcount_icon {
  height: 45px;
  width: auto;
}

.countcard {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  height: 100%;
  min-width: 221px;
  width: 33%;
  padding: 20px;
  border-right: 0.3px solid #80808094;
}

.cardcount {
  color: #858796 !important;
  font-weight: 400;
  line-height: 1.2;
  font-size: 1.25rem;
}
.cardhead {
  font-size: 0.7rem;
  font-weight: 700;
  color: #858796 !important;
}
.dashorderlistcontainer {
  margin: 10px;
}

.headicon {
  height: 20px;
  object-fit: contain;
}

.small-header {
  font-size: 13px;
  color: #858796;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0px;
}
.table {
  color: #858796;
  font-size: 13px;
}
table tr {
  box-shadow: 0 0 2px #c1c1c1;
  border-radius: 2px;
  border-top: none;
  text-align: left;
}

.table thead th {
  line-height: 1.3em;
  text-align: center;
  background: #297ec6;
  vertical-align: middle;
  /* vertical-align: bottom; */
  color: #fff;
  font-weight: 400;
  padding: 9px 0.75rem;
  font-size: 13px;
  font-weight: 500;
}

.table th:nth-child(odd) {
  background: #297ec6eb;
}

.table td:nth-child(odd) {
  background: #f9f9f9;
}

.table td {
  text-align: center;
  margin-bottom: 10px;
  padding: 8px 10px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  max-width: 230px;
}

.dashnotifcationcontainer {
  margin: 10px;
}

.notificationbox {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(187, 198, 220, 0.5);
  min-height: 76vh;
}

.notificationhead {
  background: #297ec6;
  /* border: 3px !important; */
  padding: 8px 16px;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
}

.nodata {
  color: #c1c1c1;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 900px) {
  .subcontainer {
    width: 100%;
  }
  .maincontainer {
    width: 100%;
  }
}
@media screen and (max-width: 526px) {
  .countcard {
    width: 100%;
    border: none;
  }
  /* .maincontainer {
    width: 100%;
  } */
}
