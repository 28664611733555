.verticaline {
  height: 0.5px;
  background-color: #9d9d9da3;
  width: 100%;
  margin: 28px 0px;
}

.async-select-form-control {
  font-size: 12px;
  color: #b2b2b2;
  margin-bottom: 10px;
}

.asyncselectlbl {
  position: absolute;
  top: 0px;
  font-size: 12px;
  color: #9d9d9d;
}
