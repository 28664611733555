.headerlogo {
  height: 38px;
  object-fit: contain;
}
.head_container {
  padding: 10px;
  border-bottom: '1px solid #d2d2d2';
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 0 1px #ebeef0;
  border-top: 0.001em solid #4ca2e4;
  align-items: center;
  background-color: #fff;
}
img.main_menu_profile {
  width: 37px;
  height: 37px;
  object-fit: cover;
  border-radius: 50px;
  /* border: 1px solid gray; */
}

.main_menu_abb {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50px;
  background-color: #dedede;
  display: flex;
  align-items: center;
  color: #313131;
  justify-content: center;
  font-weight: 700;
  text-decoration-line: none;
  font-size: 14px;
}

.globalicon {
  height: 38px;
  width: 38px;
  object-fit: contain;
}

.veritcalline {
  height: 30px;
  width: 1px;
  background-color: #898989;
}

.slogan {
  text-align: center;
  font-size: 14px;
  color: #297ec6;
  font-weight: 500;
  width: 80%;
}

.navcontainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  border-bottom: 1px solid #dadfe3;
  /* padding: 3px 5px; */
  background-color: #f8f9fa;
  border-radius: 0;
  /* padding: 6px 0px; */
}

.active {
  border-bottom: 3px solid #449de4;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.navitem {
  color: #449de4;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 6px 20px;
  cursor: pointer;
}

.navitemicon {
  width: 13px;
  height: 13px;
  object-fit: contain;
}
.navitemtxt {
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
}

.page-item {
  height: 23px;
  width: 23px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 3px;
}

.paginationtext {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.38px;
  text-align: left;
  text-decoration: none;
  color: #656f7d;
}
.nextprevicon {
  width: 23px;
  height: 23px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #a6a7aa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nextprev {
  width: 15;
  height: 15px;
  object-fit: contain;
}

.page-item.activepage {
  background-color: #f89422;
}

.paginationtext.activepage {
  color: #fff;
}

.nextprevicon.disabled {
  opacity: 0.4;
}

.moreoptions {
  position: absolute;
  width: 165px;
  padding: 10px 0px;
  top: 37px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 1000;
  border: 1px solid #e3e6f0;
  box-shadow: 0px 0px 0.5px 0 grey;
}

.optionitem {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #297ec6;
  text-align: inherit;
  white-space: nowrap;
  background-color: whitesmoke;
  border: 0;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
}

.optionitem:hover {
  color: #5e5e5e;
  text-decoration: none;
  background-color: #f8f9fc;
}

.nodata {
  max-width: 385px;
  margin: 0 auto;
  display: block;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitbtn {
  padding: 5px 10px;
  background-color: #0a58ca;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}
.greettext {
  font-size: 12px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .navitem {
    width: 30%;
  }
  .slogan {
    display: none;
  }
  .greettext {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .navitem {
    width: 50%;
  }
}

body {
  background: #fff;
}

#load {
  position: absolute;
  width: 600px;
  height: 36px;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#load div {
  position: absolute;
  width: 20px;
  height: 36px;
  opacity: 0;
  font-family: Helvetica, Arial, sans-serif;
  animation: move 2s linear infinite;
  -o-animation: move 2s linear infinite;
  -moz-animation: move 2s linear infinite;
  -webkit-animation: move 2s linear infinite;
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  color: #35c4f0;
}

#load div:nth-child(2) {
  animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
#load div:nth-child(3) {
  animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}
#load div:nth-child(4) {
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
#load div:nth-child(5) {
  animation-delay: 0.8s;
  -o-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}
#load div:nth-child(6) {
  animation-delay: 1s;
  -o-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
#load div:nth-child(7) {
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    left: 41%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    left: 59%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    left: 100%;
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    left: 41%;
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    left: 59%;
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    left: 100%;
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    left: 41%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    left: 59%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    left: 100%;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    left: 41%;
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    left: 59%;
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    left: 100%;
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeIn 0.9s forwards; /* Adjust duration as needed */
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
